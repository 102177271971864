import en from './i18n/locales/en.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en, // English
  },
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  escapeParameter: true,
  warnHtmlMessage: true,
}))
